import * as CryptoJS from 'crypto-js';

const delimiter = CryptoJS.enc.Hex.parse('61'.repeat(32))

export function parseFilter<T>(data: string): T {
    const bytes = CryptoJS.AES.decrypt(data, delimiter, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.ZeroPadding
    })
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}
