import { makeAutoObservable } from "mobx"
import { MultiValue, SingleValue } from "react-select"
import { Providers, IFiltersListExtendedElement, IActiveFilters, Inputs, ISelectInput, INPUT_TYPES, IRangeInput, OTOMOTO, OLX, ALLEGRO, SPRZEDAJEMY } from './store.types'
import { buildFromFilters } from "../components/LinksList/Builder"
import { FilterElement } from "../components/Filters/FilterElement"

export const providers: Providers = {
  [OTOMOTO]: 'https://otomoto.pl/osobowe',
  [OLX]: 'https://olx.pl/d/motoryzacja/samochody',
  [ALLEGRO]: 'https://allegro.pl/kategoria',
  [SPRZEDAJEMY]: 'https://sprzedajemy.pl/motoryzacja/samochody-osobowe'
}

export function checkIfSingleValue(data: MultiValue<FilterElement> | SingleValue<FilterElement>): data is FilterElement {
  return !!data && !Array.isArray(data)
}

export function checkIfMultiValue(data: MultiValue<FilterElement> | SingleValue<FilterElement>): data is FilterElement[] {
  return !!data && Array.isArray(data)
}

export function checkIfSelectOptions(data: Inputs): data is ISelectInput {
  return data.type === INPUT_TYPES.SELECT
}

export function checkIfRangeOptions(data: Inputs): data is IRangeInput {
  return data.type === INPUT_TYPES.RANGE
}

class Store {
  filtersList: IFiltersListExtendedElement[] = []
  activeFilters: IActiveFilters = {}

  constructor() {
    makeAutoObservable(this)
  }

  get providers(): string[] {
    return Object.keys(providers)
  }

  get filtersToLinkBuilders() {
    return buildFromFilters(this.activeFilters)
  }
}

export default new Store()