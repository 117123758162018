import { MultiValue, SingleValue } from "react-select";
import store, { checkIfMultiValue, checkIfSingleValue, providers } from "../../store/store";
import { ALLEGRO, IActiveFilters } from "../../store/store.types";
import { applyBrandOrFallback } from "./BrandFallbacks";
import { FilterElement } from "../Filters/FilterElement";
import { applyModelOrFallback } from "./ModelFallbacks";
import { applySearchQuery } from "./SearchBuilders";
import { toJS } from "mobx";

const defaultAllegroCategory = "/samochody-osobowe-4029"

export class LinkBuilder {
  baseUrl: string;
  path: string;
  queryParams: string;
  searchQuery: string;

  constructor(baseUrl: string, pathUrl: string) {
    this.baseUrl = baseUrl;
    this.path = pathUrl
    this.queryParams = ""
    this.searchQuery = ""
  }

  appendQueryParam(param: string) {
    const delimiter = this.queryParams ? '&' : '?'
    this.queryParams += delimiter + param
  }

  appendPath(path: string) {
    this.path += `/${path}`
  }

  assemble(): string {
    return `${this.baseUrl}${this.path}${this.queryParams}`
  }

  appendSearch(name: string) {
    if (this.searchQuery) {
      this.searchQuery += ' '
    }
    this.searchQuery += name
  }

  apply(filter: FilterElement, provider: string) {
    if (!filter.name) {
      return
    }
    const value = filter.valueFor(provider)

    if (filter.providers[provider]?.includes('=')) {
      this.appendQueryParam(value)
    } else if (value) {
      this.appendPath(value)
    }
  }
}

export type LinkBuilders = {
  [k: string]: LinkBuilder
}

const initLinkBuilders = (brandFilter?: SingleValue<FilterElement> | MultiValue<FilterElement>): LinkBuilders => {
  return Object.keys(providers).reduce<LinkBuilders>((urlBuilders, provider) => {
    var pathUrl = ''
    if (provider === ALLEGRO && !brandFilter) {
      pathUrl = defaultAllegroCategory
    }
    urlBuilders[provider] = new LinkBuilder(providers[provider], pathUrl)
    return urlBuilders
  }, {})
}

const applyBrandModelFilters = (activeFilters: IActiveFilters, builders: LinkBuilders) => {
  const brands = activeFilters['brands']
  const models = activeFilters['models']
  if (checkIfSingleValue(brands)) {
    applyBrandOrFallback(brands, builders, checkIfSingleValue(models))

    if (checkIfSingleValue(models)) {
      applyModelOrFallback(brands, models, builders)
    }
  }
}

export const buildFromFilters = (activeFilters: IActiveFilters): LinkBuilders => {
  var builders = initLinkBuilders(activeFilters['brands'])
  applyBrandModelFilters(activeFilters, builders)

  builders = Object.keys(activeFilters).reduce<LinkBuilders>((urlBuilders, filterId) => {
    if (['brands', 'models'].includes(filterId)) {
      // do not apply brands and models, since they are already applied above
      return urlBuilders
    }
    const filter = activeFilters[filterId]
    if (filter) {
      store.providers.forEach(provider => {
        const builder = urlBuilders[provider]
        if (checkIfMultiValue(filter)) {
          if (filter.length) {
            filter.forEach(filtersArrayItem => {
              builder.apply(filtersArrayItem, provider)
            })
          }
        } else if (checkIfSingleValue(filter)) {
          if (filterId == 'search' && filter.name) {
            builder.appendSearch(filter.name)
          } else {
            builder.apply(filter, provider)
          }
        }
      })
    }
    return urlBuilders
  }, builders)

  applySearchQuery(builders)

  return builders
}
