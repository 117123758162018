import {observer} from 'mobx-react';
import * as React from 'react';
import { IActiveFilters, IFiltersListExtendedElement, IRangeInput, IOption } from '../../../store/store.types';
import { FilterElement } from "../../Filters/FilterElement";
import * as Styled from './Range.styles';
import { MultiValue, SingleValue } from "react-select"
import Creatable from 'react-select/creatable'
import { checkIfRangeOptions, checkIfSingleValue } from '../../../store/store';
import rangeStyles from "../../../theme/range";

export interface IRangeProps {
  filter: IFiltersListExtendedElement
  activeFilters: IActiveFilters
  isDisabled: boolean
  onChange: (filterId: string, selection: SingleValue<FilterElement> | MultiValue<FilterElement>, subfilterDir?: string) => Promise<void>
}

export const Range: React.FC<IRangeProps> = observer(({filter, activeFilters,isDisabled, onChange}) => {
  const input: IRangeInput | undefined = checkIfRangeOptions(filter.input) ? filter.input : undefined
  const objectFrom = filter.list?.find(el => el.name === 'from')
  const objectTo = filter.list?.find(el => el.name === 'to')

  const filterIdFrom = `${filter.id}:from`
  const filterIdTo = `${filter.id}:to`

  const onCreateOption = (filterId: string, inputValue: string, providers: {[k: string]: string}) => {
    const valueAsNumber = Number(inputValue)

    if (!Number.isNaN(valueAsNumber) && valueAsNumber) {
      onChange(filterId, new FilterElement({
        name: valueAsNumber.toString(),
        providers: providers
      }))
    }

    const selectionForFrom = converSelectedFilterValueToOption(activeFilters[filterIdFrom])
    const selectionForTo = converSelectedFilterValueToOption(activeFilters[filterIdTo])
    if (selectionForFrom && selectionForTo) {
      const selectionForFromValueAsNumber = Number(selectionForFrom.value)
      const selectionForToValueAsNumber = Number(selectionForTo.value)
      if (!Number.isNaN(selectionForFromValueAsNumber) && !Number.isNaN(selectionForToValueAsNumber)) {
        if (selectionForFromValueAsNumber > selectionForToValueAsNumber) {
          activeFilters[filterId === filterIdFrom ? filterIdTo : filterIdFrom] = null
        }
      }
    }
 }

  const converSelectedFilterValueToOption = (value: SingleValue<FilterElement> | MultiValue<FilterElement>): IOption | null => {
    if (value && checkIfSingleValue(value)) {
      return {
        label: labelFormatter(value.name.toString()),
        value: value.name
      }
    } else return null 
  }

  const labelFormatter = (label: string) => {
    const valueAsNumber = Number(label)
    if (!Number.isNaN(valueAsNumber) && valueAsNumber) {
      return `${valueAsNumber.toLocaleString()}${input?.unit ? ` ${input.unit}` : ''}`
    } else return label
  }

  const filterToValues = (value: string | number) => {
    const selectionForFrom = converSelectedFilterValueToOption(activeFilters[filterIdFrom])
    if (selectionForFrom) {
      const selectionForFromValue = Number(selectionForFrom?.value)
      if (!Number.isNaN(selectionForFromValue) && value > selectionForFromValue) {
        return true
      } else return false
    } else return true
  }

  const filterFromValues = (value: string | number) => {
    const selectionForTo = converSelectedFilterValueToOption(activeFilters[filterIdTo])
    if (selectionForTo) {
      const selectionForToValue = Number(selectionForTo?.value)
      if (!Number.isNaN(selectionForToValue) && value < selectionForToValue) {
        return true
      } else return false
    } else return true
  }

  const onSelection = async (source: string, selectedElement: SingleValue<IOption>, providers: {[k: string]: string}) => {
    const convertedToFilterObject: SingleValue<FilterElement> = selectedElement ? new FilterElement({
      name: selectedElement.value.toString(),
      providers: providers
    }) : null
    await onChange(source, convertedToFilterObject, filter.subfiltersDir)

  }
  
  if (input) {
    const optionsFrom = input.options?.find(el => el.for === 'from')
    const optionsTo = input.options?.find(el => el.for === 'to')

    if (objectFrom && objectTo) {
      const listFrom = objectFrom.values?.filter(filterFromValues).map<IOption>(value => ({
        label: labelFormatter(value.toString()),
        value: value
      }))

      const listTo = objectTo.values?.filter(filterToValues).map<IOption>(value => ({
        label: labelFormatter(value.toString()),
        value: value
      }))

      return (
        <Styled.RangeWrapper>
          <p className='label'>{filter.label}</p>
          <Styled.RangeSelectsWrapper>
            <Styled.RangeFromWrapper>
              <Creatable
                styles={rangeStyles}
                options={listFrom} 
                onChange={(value) => {onSelection(filterIdFrom, value, objectFrom.providers)}}
                value={converSelectedFilterValueToOption(activeFilters[filterIdFrom])}
                isSearchable={true}
                isClearable={true}
                placeholder={optionsFrom?.placeholder}
                onCreateOption={(inputValue: string) => {onCreateOption(filterIdFrom, inputValue, objectFrom.providers)}}
                formatCreateLabel={inputValue => `Szukaj wartości: ${inputValue}`}
                createOptionPosition='first'
              />
            </Styled.RangeFromWrapper>
            <Styled.RangeToWrapper>
            <Creatable
                styles={rangeStyles}
                options={listTo}
                onChange={(value) => {onSelection(filterIdTo, value, objectTo.providers)}}
                value={converSelectedFilterValueToOption(activeFilters[filterIdTo])}
                isSearchable={true}
                isClearable={true}
                placeholder={optionsTo?.placeholder}
                onCreateOption={(inputValue: string) => {onCreateOption(filterIdTo, inputValue, objectTo.providers)}}
                formatCreateLabel={inputValue => `Szukaj wartości: ${inputValue}`}
                createOptionPosition='first'
              />
            </Styled.RangeToWrapper>
          </Styled.RangeSelectsWrapper>
        </Styled.RangeWrapper>
      )
    }
  }
  return null
});
