import {Dispatch, SetStateAction} from "react";

// https://stackoverflow.com/questions/71935664/reacttooltip-hide-doesnt-hide-tooltip-instantly
const addTooltip = (text: string, setState: Dispatch<SetStateAction<boolean>>) => ({
  "data-tip": text,
  onMouseEnter: () => setState(true),
  onMouseLeave: () => {
    setState(false);
    setTimeout(() => setState(true), 50);
  }
});

export default addTooltip;

export type TooltipProps = {
  setTooltip: Dispatch<SetStateAction<boolean>>;
};
