import { link } from "fs";
import { LinkBuilder } from "../LinksList/Builder";

export interface IFilterElement {
  name: string;
  providers: {
    [k: string]: string;
  };
  transformedProviders?: {
    [k: string]: string;
  };
  models?: string;
  values?: Array<string | number>
}

export class FilterElement  {
  name: string;
  providers: {
    [k: string]: string;
  };
  transformedProviders?: {
    [k: string]: string;
  };
  models?: string;
  values?: Array<string | number>

  constructor(initData: IFilterElement) {
    this.name = initData.name
    this.providers = initData.providers
    this.transformedProviders = initData.transformedProviders
    this.models = initData.models
    this.values = initData.values
  }

  valueFor(provider: string): string {
    if (this.providers[provider]) {
      return this.providers[provider].replace('{{value}}', this.name)
    }
    return ''
  }
}
