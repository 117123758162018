import { observer } from "mobx-react"
import store from "../../store/store"
import LinksListWrapper from './LinksList.styled'
import { ArrowForwardOutlined } from "@mui/icons-material";
import labels from '@assets/labels.json';
import ReactTooltip from "react-tooltip";
import {colors} from "../../const";
import addTooltip, {TooltipProps} from "../../utils/tooltipHelper";
import {FC} from "react";

export const LinksList = observer<FC<TooltipProps>>(({setTooltip}) => {

  const linkBuilders = store.filtersToLinkBuilders;

  const viewAllProviders = () => {
    Object.keys(linkBuilders)
      .forEach(provider =>
        window.open(linkBuilders[provider].assemble())
      );
  }

  const generateLinksList = () => {
    return Object.keys(linkBuilders).map((provider, idx) =>
      <a id={`link-${provider}`}
         key={idx}
         className='link-button'
         href={linkBuilders[provider].assemble()}
         target='_blank'>
        <div className='content'>
          {provider.toUpperCase()}
        </div>
        <ArrowForwardOutlined className='arrow'/>
      </a>
    );
  }

  return (
    <LinksListWrapper>
      {generateLinksList()}
      <a {...addTooltip(labels.results.tooltips.all, setTooltip)}
         className='link-button button-primary'
         key='all'
         onClick={viewAllProviders}>
        <div className='content'>
          {labels.results.all}
        </div>
        <ArrowForwardOutlined className='arrow'/>
      </a>
    </LinksListWrapper>
  )
})
