import selectStyles from "./select";
import {GroupBase, StylesConfig} from "react-select";
import {IOption} from "../store/store.types";
import {border, colors} from "../const";

const rangeStyles: StylesConfig<IOption, false, GroupBase<IOption>> = {
  ...selectStyles as unknown as StylesConfig<IOption, false, GroupBase<IOption>>,
  control: (provided, state) => ({
    ...provided,
    borderRadius: border.radius,
    borderWidth: border.width,
    borderColor: colors.black,
    backgroundColor: state.hasValue ? colors.black : colors.white,
    width: 170,
    minHeight: 50,
    opacity: state.isDisabled ? .3 : 1,
    boxShadow: colors.primary,
    cursor: "pointer",
    ":hover": {
      borderColor: colors.primary
    }
  }),
}

export default rangeStyles;
