import {observer} from 'mobx-react';
import * as React from 'react';
import { IActiveFilters, IFiltersListExtendedElement, ISelectInput } from '../../../store/store.types';
import { FilterElement } from "../../Filters/FilterElement";
import * as Styled from './SelectInput.styles';
import Select, { MultiValue, SingleValue } from "react-select"
import { checkIfSelectOptions } from '../../../store/store';
import selectStyles from "../../../theme/select";

export interface ISelectInputProps {
  filter: IFiltersListExtendedElement
  activeFilters: IActiveFilters
  isDisabled: boolean
  onChange: (filterId: string, selection: SingleValue<FilterElement> | MultiValue<FilterElement>, subfilterDir?: string) => Promise<void>
}

export const SelectInput: React.FC<ISelectInputProps> = observer(({filter, activeFilters, isDisabled, onChange}) => {

  const input: ISelectInput | undefined = checkIfSelectOptions(filter.input) ? filter.input : undefined
  
  return input ? (
    <Styled.SelectInputWrapper>
      <p className='label'>{filter.label}</p>
        <Select
          styles={selectStyles}
          isDisabled={isDisabled}
          options={filter.list} 
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          onChange={(value) => {onChange(filter.id, value, filter.subfiltersDir)}}
          isMulti={input.options ? input.options.multi : false}
          value={isDisabled ? null : activeFilters[filter.id]}
          isSearchable={true}
          isClearable={true}
          placeholder={input.options ? input.options.placeholder : undefined}
        />
    </Styled.SelectInputWrapper>
  ) : null;
});
