import styled from "styled-components";
import {colors} from "../../const";

const LinksListWrapper = styled.div.attrs({
  className: 'LinksListWrapper'
})`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  
  .link-button {
    cursor: pointer;
    height: 50px;
    width: 360px;
    background-color: ${colors.black};
    color: ${colors.white};
    margin: 20px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;

    box-sizing: border-box;
    border: 2px solid ${colors.black};
    .content {
      margin: 0 2px;
    }
  }
  .link-button:hover {
    border-color: ${colors.primary};
  }
  .button-primary {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
  }
  .button-primary:hover {
    border-color: ${colors.black};
  }
`

export default LinksListWrapper;
