import styled from "styled-components";
import {colors} from "../../../const";

const DividerWrapper = styled.div.attrs({
  className: 'DividerWrapper'
})`
  width: 100%;
  position: relative;
  margin-top: 48px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .divider {
    width: 100%;
    height: 0;
    border-bottom: 2px solid ${colors.primary}
  }
  
  .divider-label {
    position: absolute;
    top: -8px;
    padding: 0 16px;
    background-color: ${colors.white};
  }
`

export default DividerWrapper;
