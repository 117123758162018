import * as React from 'react';
import {createRoot} from 'react-dom/client'
import {App} from './App'
import GlobalStyles from './globalStyles'

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <GlobalStyles />
      <App />
    </React.StrictMode>,
  )
}

