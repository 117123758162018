import {observer} from 'mobx-react';
import * as React from 'react';
import * as Styled from './TextInput.styles';
import { IActiveFilters, IFiltersListExtendedElement, IRangeInput, IOption, ITextInput, Inputs, INPUT_TYPES } from '../../../store/store.types';
import { FilterElement } from "../../Filters/FilterElement";
import { MultiValue, SingleValue } from "react-select"
import { toJS } from 'mobx';
import { Search } from "@mui/icons-material";
import { colors } from '../../../const';

export interface ITextInputProps {
  filter: IFiltersListExtendedElement
  activeFilters: IActiveFilters
  isDisabled: boolean
  onChange: (filterId: string, selection: SingleValue<FilterElement> | MultiValue<FilterElement>, subfilterDir?: string) => Promise<void>
}

function checkIfSearchInput(data: Inputs): data is ITextInput {
  return data.type === INPUT_TYPES.TEXT
}

export const TextInput: React.FC<ITextInputProps> = observer(({filter, onChange, activeFilters}) => {

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(filter.id, new FilterElement({
      name: e.currentTarget.value,
      providers: {...filter.list![0].providers}
    }))
  }

  const input: ITextInput | undefined = checkIfSearchInput(filter.input) ? filter.input : undefined

  return (
    <Styled.TextInputWrapper>
      <p className='label'>{filter.label}</p>
      <Styled.TextInputRow>
        <Styled.TextInput type='text' onChange={onChangeValue} placeholder={input?.options?.placeholder} />
        <Search htmlColor={colors.primary}/>
      </Styled.TextInputRow>
      
    </Styled.TextInputWrapper>
  );
});
