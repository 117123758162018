import {GroupBase, StylesConfig} from "react-select";
import { FilterElement } from "../components/Filters/FilterElement";
import {border, colors} from "../const";

const selectStyles: StylesConfig<FilterElement, boolean, GroupBase<FilterElement>> = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: border.radius,
    borderWidth: border.width,
    borderColor: colors.black,
    backgroundColor: state.hasValue ? colors.black : colors.white,
    width: 360,
    minHeight: 50,
    opacity: state.isDisabled ? .3 : 1,
    boxShadow: colors.primary,
    cursor: "pointer",
    ":hover": {
      borderColor: colors.primary
    }
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.hasValue ? colors.white : colors.black
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.white
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: border.radius,
    backgroundColor: colors.white
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: border.radius
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.black
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.hasValue ? colors.white : colors.primary,
    ":hover": {
      color: colors.primary,
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: colors.white,
    ":hover": {
      color: colors.primary,
    }
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: border.radius,
    borderWidth: border.width,
    borderColor: colors.black,
    borderStyle: "solid",
    borderTopWidth: 0,
    backgroundColor: colors.white,
    margin: 0,
    boxShadow: '0 4px 0 rgba(0,0,0,.1)'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor: state.isSelected ? colors.black : colors.white,
    color: state.isSelected ? colors.white : colors.black,
    ":hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  })
}

export default selectStyles;
