import { MultiValue, SingleValue } from "react-select"
import { FilterElement } from "../components/Filters/FilterElement"
import { LinkBuilder } from "../components/LinksList/Builder"

export enum INPUT_TYPES {
  SELECT='select',
  RANGE='range',
  TEXT='textinput'
}

export interface IOption {
  value: string | number
  label: string
}

export interface IRangeOption {
  for?: 'from' | 'to'
  placeholder?: string
  customValueEnabled?: boolean
}

export type IRangeOptions = IRangeOption[] & {disabledTooltip?: string }
export interface ISelectOption {
  multi?: boolean,
  placeholder?: string,
  disabledTooltip?: string,
  customValueEnabled?: boolean
}

export interface ITextInputOption {
  placeholder?: string,
  disabledTooltip?: string,
}

export interface ISelectInput {
  type: INPUT_TYPES.SELECT,
  options?: ISelectOption
}

export interface IRangeInput {
  type: INPUT_TYPES.RANGE,
  unit?: string
  options?: IRangeOptions
}

export interface ITextInput {
  type: INPUT_TYPES.TEXT,
  options?: ITextInputOption
}

export type Inputs = ISelectInput | IRangeInput | ITextInput

export interface IFiltersListElement {
  id: string,
  label: string,
  file: string,
  subfiltersDir?: string
  input: Inputs
}

export type Providers = {[k: string]: string}

export interface IFiltersListExtendedElement extends IFiltersListElement {
list?: FilterElement[]
}

export interface IRangeFilterElementResponse {
  values: Array<string | number>
  filters: FilterElement[]
}

export interface IActiveFilters {
  [k: string]: SingleValue<FilterElement> | MultiValue<FilterElement>
}

export const OTOMOTO = "otomoto"
export const OLX = "olx"
export const ALLEGRO = "allegro"
export const SPRZEDAJEMY = "sprzedajemy"
