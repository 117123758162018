import axios from 'axios'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { MultiValue, SingleValue } from "react-select"
import { IFiltersListElement, INPUT_TYPES, IRangeFilterElementResponse } from '../../store/store.types'
import { FilterElement, IFilterElement } from "./FilterElement"
import store, { checkIfSingleValue } from '../../store/store'
import { Range, SelectInput, TextInput } from '../FormElements'
import FiltersWrapper from "./Filters.styled";
import addTooltip, { TooltipProps } from "../../utils/tooltipHelper";
import { parseFilter } from './Parser'


const filtersDirPath = 'https://storage.googleapis.com/furami-search/dev-engine/assets/filters'
const IndexListUrl = `${filtersDirPath}/bec0787115d20c29b8340a0ee65c495b.txt`


// const filtersDirPath = 'assets/filters/'
// const IndexListUrl = `assets/filters/filters-list.json`
// const IndexListUrl = `${filtersDirPath}/bec0787115d20c29b8340a0ee65c495b.txt`

function convertToFilterElements(data: string): FilterElement[] {
  const rawFilters: IFilterElement[] = parseFilter(data)

  return rawFilters.map(rawFilter => new FilterElement(rawFilter))
}


function convertRangeFilterResponseDataToFilterElement(data: string): FilterElement[] {
  const convertedFromString: IRangeFilterElementResponse = parseFilter(data)
  const dataFrom = convertedFromString.filters.find(filter => filter.name === 'from')
  const dataTo = convertedFromString.filters.find(filter => filter.name === 'to')
  if (dataFrom && dataTo) {
    return [
      new FilterElement({
        ...dataFrom,
        values: convertedFromString.values
      }),
      new FilterElement({
        ...dataTo,
        values: convertedFromString.values
      })
    ]
  } else {
    return []
  }

}

export const Filters = observer<TooltipProps>(({ setTooltip }) => {
  const [isPending, setIsPending] = React.useState(true)

  React.useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get<IFiltersListElement[]>(IndexListUrl, {transformResponse: parseFilter})
        const res = await Promise.all(data.map(async (filterListElement) => {
          if (filterListElement.file) {
            const { data } = await axios.get<FilterElement[]>(`${filtersDirPath}/${filterListElement.file}`, {
              transformResponse: (data: string): FilterElement[] => {
                switch (filterListElement.input.type) {
                  case INPUT_TYPES.RANGE:
                    return convertRangeFilterResponseDataToFilterElement(data)
                  case INPUT_TYPES.TEXT:
                  case INPUT_TYPES.SELECT:
                  default:
                    return convertToFilterElements(data)
                }
              }
            })
            return {
              ...filterListElement,
              list: data
            }
          } else {
            return { ...filterListElement }
          }
        }))
        store.filtersList = res
      }
      catch (err: unknown) {
        //to-do error handler
      }
      finally {
        setIsPending(false)
      }
    })()


  }, [])


  const generateFiltersList = () => store.filtersList.map(filter => {
    const disabled = !filter.list || (filter.id === 'models' && !store.activeFilters['brands']);
    const tooltipProps = disabled ? addTooltip(filter.input.options?.disabledTooltip ?? '', setTooltip) : {};
    switch (filter.input.type) {
      case INPUT_TYPES.SELECT: {
        return <div className='filter-container' {...tooltipProps}>
          <SelectInput
            filter={filter}
            isDisabled={disabled}
            activeFilters={store.activeFilters}
            onChange={onSelection}
            key={filter.id}
          />
        </div>;
      }
      case INPUT_TYPES.RANGE: {
        return <div className='filter-container' {...tooltipProps}>
          <Range
            filter={filter}
            isDisabled={disabled}
            activeFilters={store.activeFilters}
            onChange={onSelection}
            key={filter.id}
          />
        </div>
      }
      case INPUT_TYPES.TEXT: {
        return <div className='filter-container' {...tooltipProps}>
          <TextInput
            filter={filter}
            isDisabled={disabled}
            activeFilters={store.activeFilters}
            onChange={onSelection}
            key={filter.id}
          />
        </div>
      }
    }

  })

  const onSelection = async (filterId: string, selection: SingleValue<FilterElement> | MultiValue<FilterElement>, subfilterDir?: string) => {
    runInAction(() => {
      store.activeFilters[filterId] = selection
    })
    if (filterId === 'brands') {
      const models = store.filtersList.find(filter => filter.id === 'models')
      if (models) {
        runInAction(() => {
          store.activeFilters['models'] = null
        })

        if (selection && checkIfSingleValue(selection) && subfilterDir) {
          const { data } = await axios.get<FilterElement[]>(`${filtersDirPath}/${selection.models}`, {transformResponse: parseFilter})
          if (data) {
            models['list'] = data
          }
        } else {
          models['list'] = []
        }
      }
    }
  }
  return isPending
    ? <p>loading...</p>
    : <FiltersWrapper>
      {generateFiltersList()}
    </FiltersWrapper>
})


