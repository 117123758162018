import store from "../../store/store";
import { ALLEGRO } from "../../store/store.types";
import { FilterElement } from "../Filters/FilterElement";
import { LinkBuilders } from "./Builder";
import { fallbackBrand } from "./FallbackHelpers";


export const applyBrandOrFallback = (brand: FilterElement, builders: LinkBuilders, modelChoosen: boolean) => {
    store.providers.forEach(provider => {
        const builder = builders[provider]
        if (brand.providers[provider]) {
            if (provider != ALLEGRO || !modelChoosen) {
                builder.apply(brand, provider)
            }
        } else {
            builder.apply(fallbackBrand(), provider)
            builder.appendSearch(brand.name)
        }
    })
}
