import {createGlobalStyle} from 'styled-components'
import {colors, border, fontSize} from './const'

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${colors.white}
  }
  
  .label {
    font-size: ${fontSize.label};
    color: ${colors.black};
  }
  .content {
    font-size: ${fontSize.content};
  }
  .tooltip {
    opacity: 1!important;
    border-radius: 0!important;
    border-width: 2px!important;
  }
  *: focus {
    outline: none;
  }
`;

export default GlobalStyles
