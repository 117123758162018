import styled from 'styled-components'
import { border, colors } from '../../../const'

export const TextInputWrapper = styled.div.attrs({
  className: 'TextInputWrapper'
})``

export const TextInputRow = styled.div.attrs({
  className: 'TextInputRow'
})`
  padding-right: 10px;
  display: grid;
  grid-auto-flow: column;
  width: 360px;
  grid-template-columns: 1fr max-content;
  align-items: center;
  box-sizing: border-box;
  border: ${border.width} solid ${colors.black};
  &:hover, &:focus {
    border-color: ${colors.primary};
  }
`

export const TextInput = styled.input.attrs({
  className: 'TextInput'
})`
  border: none;
  background-color: transparent;
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Questrial',sans-serif;
  color: ${colors.black};
  font-size: 16px;
  border: 0 !important;
  &:focus {
    outline: 0 !important;
    border: 0 !important;
  }
`