import styled from "styled-components";

const FiltersWrapper = styled.div.attrs({
  className: 'FiltersWrapper'
})`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  .filter-container {
    margin-left: 20px;
    margin-right: 20px;
    .label {
      margin-bottom: .5em;
      margin-top: 1.5em;
    }
  }
`

export default FiltersWrapper;
