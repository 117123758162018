import styled from 'styled-components'

export const RangeWrapper = styled.div.attrs({
  className: 'RangeWrapper'
})``

export const RangeSelectsWrapper = styled.div.attrs({
  className: 'RangeFromWrapper'
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 360px;
`

export const RangeFromWrapper = styled.div.attrs({
  className: 'RangeFromWrapper'
})`

`

export const RangeToWrapper = styled.div.attrs({
  className: 'RangeToWrapper'
})``
