import * as React from 'react'
import * as Styled from './App.styles'
import { Filters } from '../components/Filters'
import { LinksList } from '../components/LinksList'
import {Divider} from "../components/Misc/Divider";
import labels from "@assets/labels.json";
import ReactTooltip from "react-tooltip";
import {colors} from "../const";
import {useState} from "react";

export const App: React.FC<{}> = () => {
  const [tooltip, setTooltip] = useState<boolean>(true);

  return (
    <Styled.AppWrapper>
      {tooltip && <ReactTooltip
          className='tooltip'
          effect='solid'
          border
          textColor={colors.black}
          backgroundColor={colors.white}
          borderColor={colors.black}
          multiline
      />}
      <Divider label={labels.filters.label}/>
      <Filters setTooltip={setTooltip}/>
      <Divider label={labels.results.label}/>
      <LinksList setTooltip={setTooltip}/>
    </Styled.AppWrapper>
  )
}
