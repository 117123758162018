import store from "../../store/store"
import {ALLEGRO, OLX, OTOMOTO, SPRZEDAJEMY} from "../../store/store.types"
import { LinkBuilder, LinkBuilders } from "./Builder"


type searchBuilder = (builder: LinkBuilder) => void

const normalize = (input: string, delimiter: string) => {
    return input.replace(' ', delimiter).replace(' ', delimiter).replace(' ', delimiter).replace(' ', delimiter).replace(' ', delimiter).replace(' ', delimiter).replace(' ', delimiter);
}

const sprzedajemySearchBuilder = (builder: LinkBuilder) => {
    const search = normalize(builder.searchQuery, '+')
    builder.appendQueryParam('inp_text%5Bv%5D=' + search)
}
 
const olxOtomotoSearchBuilder = (builder: LinkBuilder) => {
    const search = normalize(builder.searchQuery, '-')
    builder.appendPath('q-' + search)
}

const allegroSearchBuilder = (builder: LinkBuilder) => {
    const search = normalize(builder.searchQuery, ' ')
    builder.appendQueryParam('string=' + search)
}

const searchBuilders: { [key: string]: searchBuilder } = {
    [OLX]: olxOtomotoSearchBuilder,
    [ALLEGRO]: allegroSearchBuilder,
    [OTOMOTO]: olxOtomotoSearchBuilder,
    [SPRZEDAJEMY]: sprzedajemySearchBuilder,
}

export const applySearchQuery = (builders: LinkBuilders) => {
    store.providers.forEach(provider => {
        const builder = builders[provider]
        if (builder.searchQuery) {
            searchBuilders[provider](builder)
        }
    })
}