import store from "../../store/store"
import { FilterElement } from "../Filters/FilterElement"


var defaultBrand: FilterElement

export const fallbackBrand = () => {
  if (!defaultBrand) {
    defaultBrand = store.filtersList.find(filter => filter.id === 'brands')!.list?.find(filterElement => filterElement.name === 'Inna')!
  }
  return defaultBrand
}
