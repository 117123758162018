import store from "../../store/store"
import { ALLEGRO, OLX, OTOMOTO, SPRZEDAJEMY } from "../../store/store.types";
import { FilterElement } from "../Filters/FilterElement";
import { LinkBuilder, LinkBuilders } from "./Builder";
import { fallbackBrand } from "./FallbackHelpers";

interface IModelFallbackParams {
  brand: FilterElement,
  builder: LinkBuilder,
  model: FilterElement,
}

type modelFallback = ({ brand, model, builder }: IModelFallbackParams) => void

const fallbackValue = (provider: string) => {
  const models = store.filtersList.find(filter => filter.id === 'models')
  return models!.list?.find(filterElement => filterElement.name === 'Inny')?.providers[provider]
}

const olxModelFallback = ({ brand, model, builder }: IModelFallbackParams) => {
  const PROVIDER = OLX

  var modelValue: string | undefined = model.providers[PROVIDER]

  if (!modelValue) {
    builder.appendSearch(model.name)
    if (brand.providers[PROVIDER]) {
      modelValue = fallbackValue(PROVIDER)
    }
  }

  if (modelValue) {
    builder.appendQueryParam(`search[filter_enum_model][0]=${modelValue}`)
  }
}

const otomotoModelFallback = ({ brand, model, builder }: IModelFallbackParams) => {
  const PROVIDER = OTOMOTO

  var modelValue: string | undefined = model.providers[PROVIDER]
  

  if (!modelValue) {
    builder.appendSearch(model.name)
    if (brand.providers[PROVIDER]) {
      modelValue = fallbackValue(PROVIDER)
    }
  }

  if (modelValue) {
    builder.appendPath(modelValue)
  }
}

const allegroModelFallback = ({ brand, model, builder }: IModelFallbackParams) => {
  const PROVIDER = ALLEGRO

  var modelValue: string | undefined = model.providers[PROVIDER]
  

  if (!modelValue) {
    builder.appendSearch(model.name)
    if (brand.providers[PROVIDER]) {
      modelValue = fallbackValue(PROVIDER)
    }
  }

  if (modelValue) {
    builder.appendPath(modelValue)
  }
}

const sprzedajemyModelFallback = ({ brand, model, builder }: IModelFallbackParams) => {
  const PROVIDER = SPRZEDAJEMY

  var modelValue: string | undefined = model.providers[PROVIDER]
  

  if (!modelValue) {
    builder.appendSearch(model.name)
    if (brand.providers[PROVIDER]) {
      modelValue = fallbackValue(PROVIDER)
    }
  }

  if (modelValue) {
    builder.appendPath(modelValue)
  }
}

const modelFallbacks: { [key: string]: modelFallback } = {
  [OLX]: olxModelFallback,
  [ALLEGRO]: allegroModelFallback,
  [OTOMOTO]: otomotoModelFallback,
  [SPRZEDAJEMY]: sprzedajemyModelFallback
}

export const applyModelOrFallback = (brand: FilterElement, model: FilterElement, builders: LinkBuilders) => {
  store.providers.forEach(provider => {
    modelFallbacks[provider]({brand: brand, model: model, builder: builders[provider]})
  })
}

