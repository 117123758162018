export const colors = {
  primary: '#ff6400',
  white: '#f0f0f0',
  black: '#141414',
  warn: '#e6e600'
}

export const border = {
  width: '2px',
  radius: '0'
}

export const fontSize = {
  label: '18px',
  content: '16px'
}
